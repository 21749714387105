import { Box, Center, Flex, IconButton, useDisclosure, useStyleConfig, Text, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react';
import { Resizable } from 're-resizable';
import React, { createContext, Dispatch, useEffect, useState } from 'react';
import { GrSidebar } from 'react-icons/gr';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { getCurrentUser } from './api/AuthApi';
import './App.css';
import { AddEvent } from './components/AddEvent';
import { EventDetails } from './components/events/EventDetails';
import { EventsList } from './components/EventsList';
import { FormDetails } from './components/forms/FormDetails';
import { FormsList } from './components/FormsList';
import { LoginModal } from './components/LoginModal';
import { NewItem } from './components/NewItem';
import { OrganizationsMenu } from './components/OrganizationsMenu';
import { SettingsDrawer } from './components/SettingsDrawer';
import { GenericForm } from './models/GenericForm';
import { Organization } from './models/Organization';
import { RegistrationEvent } from './models/RegistrationEvent';
import { User } from './models/User';


type EventStateType = {
  selectedEvent: RegistrationEvent | null,
  selectedForm: GenericForm | null,
  selectedOrganization: Organization | null,
  currentUser: User,
}

const initialEventState = {
  selectedEvent: null,
  selectedForm: null,
  selectedOrganization: null,
  currentUser: { authenticated: false, username: "undefined" },
}

type EventsReducerPayload = {
  selectedEvent: RegistrationEvent,
  selectedForm: GenericForm,
  selectedOrganization: Organization,
}

const eventsReducer = (state: EventStateType, action: { type: string, payload: EventsReducerPayload }) => {
  switch (action.type) {
    case "SELECT_EVENT":
      return {
        ...state,
        selectedEvent: action.payload.selectedEvent,
        selectedForm: null,
      };
    case "SELECT_FORM":
      return {
        ...state,
        selectedForm: action.payload.selectedForm,
        selectedEvent: null,
      };
    case "SELECT_ORGANIZATION":
      return {
        ...state,
        selectedOrganization: action.payload.selectedOrganization,
      }
    default:
      return state;
  }
};

export const EventContext = createContext<{ state: EventStateType, dispatch: Dispatch<any> }>({ state: initialEventState, dispatch: () => null })

const queryClient = new QueryClient();

export const App: React.FunctionComponent<{}> = (props) => {

  const drawerDisclosure = useDisclosure();
  const [state, dispatch] = React.useReducer(eventsReducer, initialEventState);

  const [authenticated, setAuthenticated] = useState<Boolean>(false);

  useEffect(() => {
    getCurrentUser().then((user: User) => { state.currentUser = user; setAuthenticated(user.authenticated) })
  }, [state]);


  const sideBarStyle = useStyleConfig("SideBar", {})

  if (!authenticated) {
    return (
      <LoginModal>
      </LoginModal>
    )
  }

  else {
    return (
      <QueryClientProvider client={queryClient}>
        <Router>
          <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
          <Box>
            <EventContext.Provider value={{ state, dispatch }}>
              <Flex>
                <Resizable
                  defaultSize={{
                    width: "20%",
                    height: "100vh",
                  }}
                  minWidth="10%"
                  maxWidth="40%"
                  enable={{ top: false, right: true, bottom: false, left: false, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}
                >
                  <Box sx={sideBarStyle}>
                    <Center pt="20px" mb="20px">
                      <OrganizationsMenu></OrganizationsMenu>
                    </Center>
                    <Accordion allowToggle>
                      <AccordionItem>
                        <AccordionButton>
                          <Center><Text as="h1" fontSize="2rem">Evenementen</Text><AccordionIcon /></Center>
                        </AccordionButton>
                        <AccordionPanel p="0">
                          <Box
                            css={{
                              '&': {
                                msOverflowStyle: "none",
                                scrollbarWidth: "none",
                              },
                              '&::-webkit-scrollbar': {
                                display: "none",
                              },
                            }}>
                            <EventsList organizationId={state.selectedOrganization ? state.selectedOrganization.id : null} />
                          </Box>
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionButton>
                          <Center><Text as="h1" fontSize="2rem">Formulieren</Text><AccordionIcon /></Center>
                        </AccordionButton>
                        <AccordionPanel p="0">
                          <Box h="35%" overflowY="scroll"
                            css={{
                              '&': {
                                msOverflowStyle: "none",
                                scrollbarWidth: "none",
                              },
                              '&::-webkit-scrollbar': {
                                display: "none",
                              },
                            }}>
                            <FormsList organizationId={state.selectedOrganization ? state.selectedOrganization.id : null} />
                          </Box>
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                    <Box position="absolute" bottom="0" w="100%">
                      <AddEvent organizationId={state.selectedOrganization ? state.selectedOrganization.id : null} />
                    </Box>
                  </Box>
                </Resizable>
                <Box w="100%">
                  <IconButton aria-label="profile-settings" position="absolute" right="5px" top="5px" icon={<GrSidebar></GrSidebar>} onClick={drawerDisclosure.onOpen}>
                    Open
                    </IconButton>
                  <Switch>
                    <Route exact path="/">
                      <Box p="6">Kies een Event</Box>
                    </Route>
                    <Route path="/event">
                      <EventDetails />
                    </Route>
                    <Route path="/form">
                      <FormDetails />
                    </Route>
                    <Route path="/new">
                      <NewItem></NewItem>
                    </Route>
                  </Switch>
                </Box>
              </Flex>
            </EventContext.Provider>
          </Box>
          <SettingsDrawer isOpen={drawerDisclosure.isOpen} onClose={drawerDisclosure.onClose} onOpen={drawerDisclosure.onOpen}></SettingsDrawer>
        </Router>
      </QueryClientProvider>
    );
  }
}

export default App;
