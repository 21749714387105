import { DeleteIcon } from "@chakra-ui/icons";
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useColorModeValue } from "@chakra-ui/react";
import React, { useContext, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { deleteEvent } from "../../api/EventsApi";
import { EventContext } from "../../App";
import { EventDates } from "./EventDates";
import { EventFields } from "./EventFields";
import { EventMail } from "./EventMail";
import { EventRegistrations } from "./EventRegistrations";
import { Link as RouterLink } from "react-router-dom";
import { EventSettings } from "./EventSettings";


export const EventDetails: React.FunctionComponent<{}> = (props) => {
  const queryClient = useQueryClient();
  const { state } = useContext(EventContext);
  const [deleteEventIsOpen, setDeleteEventIsOpen] = useState(false);
  const cancelRef = useRef(null);
  const tabColor = useColorModeValue("gray.600", "whiteAlpha.900");
  const deleteEventMutation = useMutation((eventId: number) => deleteEvent(eventId), {
    onSuccess: (eventId) => {
      queryClient.invalidateQueries(["events", eventId]);
    }
  })
  if (state.selectedEvent == null) {
    return (<Box p="6">Kies een Event</Box>)
  }
  return (
    <>
      <Box p="6">
        <Box pb="10">
          <Text fontSize={40} as="h1">{state.selectedEvent.name} <DeleteIcon cursor="pointer" onClick={() => setDeleteEventIsOpen(true)}></DeleteIcon></Text>
          <Text fontSize={12}>ID: {state.selectedEvent.id}</Text>
        </Box>
        <Tabs variant="soft-rounded" colorScheme="button">
          <TabList>
            <Tab color={tabColor}>Velden</Tab>
            <Tab color={tabColor}>Datums</Tab>
            <Tab color={tabColor}>Registraties</Tab>
            <Tab color={tabColor}>Emails</Tab>
            <Tab color={tabColor}>Instellingen</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <EventFields event={state.selectedEvent}></EventFields>
            </TabPanel>
            <TabPanel>
              <EventDates eventId={state.selectedEvent.id}></EventDates>
            </TabPanel>
            <TabPanel>
              <EventRegistrations eventId={state.selectedEvent.id}></EventRegistrations>
            </TabPanel>
            <TabPanel>
              <EventMail eventId={state.selectedEvent.id}></EventMail>
            </TabPanel>
            <TabPanel>
              <EventSettings eventId={state.selectedEvent.id}></EventSettings>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      <AlertDialog
        isOpen={deleteEventIsOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setDeleteEventIsOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Verwijder dit event
            </AlertDialogHeader>

            <AlertDialogBody>
              Volgende event zal verwijderd worden: {state.selectedEvent.name}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setDeleteEventIsOpen(false)}>
                Annuleer
              </Button>
              <RouterLink to="/">
                <Button colorScheme="red" onClick={() => {
                  console.log(state.selectedEvent?.id)
                  deleteEventMutation.mutate(state.selectedEvent!.id);
                  setDeleteEventIsOpen(false);
                }
                } ml={3}>
                  Verwijder
                </Button>
              </RouterLink>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );

}