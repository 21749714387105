import { GlobalStyleProps, mode } from "@chakra-ui/theme-tools";

export const styles = {
  global: (props: GlobalStyleProps) => ({
    "html, body": {
      backgroundColor: mode("#F9F9F9", "grey.900")(props),
      color: mode("gray.600", "white.200")(props),
    },
    h1: {
      fontSize: "2.25rem",
    },
    h2: {
      fontSize: "1.5rem",
      paddingTop: "10px",
      paddingBottom: "10px"
    }
  }),
}