import { CopyIcon, DeleteIcon } from "@chakra-ui/icons";
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Center, Link, Select, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { fetchEventDates, fetchEventFields } from "../../api/EventsApi";
import { deleteRegistration, fetchRegistrations, sendRegistrationReminder } from "../../api/RegistrationsApi";
import { DaySlot } from "../../models/DaySlot";
import { Registration } from "../../models/Registration";
import { RegistrationField } from "../../models/RegistrationField";
import { FiMail } from 'react-icons/fi';


export const EventRegistrations: React.FunctionComponent<{ eventId: number }> = ({ eventId }) => {
  const queryClient = useQueryClient();
  const [daySlotFilter, setDaySlotFilter] = useState<number | null>(null);
  const [sendReminderIsOpen, setSendReminderIsOpen] = useState(false);
  const [deleteRegistrationIsOpen, setDeleteRegistrationIsOpen] = useState(false);
  const [selectedRegistration, setSelectedRegistration] = useState<Registration | null>(null);
  const cancelRef = useRef(null);

  const fields = useQuery<RegistrationField[], Error>(["fields", eventId], () => fetchEventFields(eventId));
  const eventDates = useQuery<DaySlot[], Error>(["eventDates", eventId], () => fetchEventDates(eventId));
  const registrations = useQuery<Registration[], Error>(
    ["registrations", eventId, daySlotFilter], 
    () => fetchRegistrations(eventId, daySlotFilter),
    {
      staleTime: 60 * 1000,
    }
  );

  useEffect(() => setDaySlotFilter(null), [eventId]);

  const deleteRegistrationMutation = useMutation((registrationId: number) => deleteRegistration(eventId, registrationId), {
    onSuccess: () => {
      queryClient.invalidateQueries(["registrations", eventId, daySlotFilter]);
    }
  })
  
  const filterRegistrations = (daySlotId: string) => {
    setDaySlotFilter(parseInt(daySlotId));
  }

  if (fields.status === "error" || registrations.status === "error") {
    return (
      <Center>Couldn`t fetch fields or registrations</Center>
    )
  }

  const showReminderAlert = (registration: Registration) => {
    setSelectedRegistration(registration);
    setSendReminderIsOpen(true);
  }

  const showDeleteRegistration = (registration: Registration) => {
    setSelectedRegistration(registration);
    setDeleteRegistrationIsOpen(true);
  }



  const renderRegistrations = () => {
    if (registrations.status === "loading") {
      return (
        <Tr><Td><Spinner /></Td></Tr>
      )
    }
    if (registrations.data!.length > 0) {
      return registrations.data!.map((registration: Registration, index: number) => {
        if (registration.TimeSlot !== null) {
          return (<Tr key={`tr${registration.id}`}>
            {fields.data!.map((field: RegistrationField, index: number) => {
              if (registration.fields) {
                return (
                  <Td key={`td${field.id}`}>
                    {registration.fields[field.fieldName]}
                  </Td>)
              } else {
                return (<Td key={`td${field.id}`}></ Td>)
              }
            })}
            <Td>{new Date(registration.TimeSlot.time).toLocaleDateString()}</Td>
            <Td>{new Date(registration.TimeSlot.time).toLocaleTimeString()}{registration.TimeSlot.link !== undefined && `- ${registration.TimeSlot.link}`}</Td>
            <Td><FiMail cursor="pointer" onClick={() => showReminderAlert(registration)}></FiMail></Td>
            <Td><DeleteIcon cursor="pointer" onClick={() => showDeleteRegistration(registration)}></DeleteIcon></Td>
          </Tr>)
        } else {
          return (<></>)
        }
      })
    } else {
      return <Tr><Td>Nog geen registraties</Td></Tr>
    }
  }

  if (!fields.data) {
    return (
      <Center>Couldn`t fetch fields</Center>
    )
  }

  return (
    <Box>
      <Text fontSize={24} as="h2">Registraties</Text>
      <Box w="25%" mb="10px">
        <Select onChange={(e) => filterRegistrations(e.target.value)} placeholder="Toon enkel voor dag">
          {eventDates.data && (eventDates.data).map((value: DaySlot, index: number) => {
            return (
              <option key={value.id} value={value.id}>{value.date.toLocaleDateString()}</option>
            )
          })}

        </Select>
      </Box>
      <Link href={`/api/registrations/${eventId}/csv`} isExternal><CopyIcon />Exporteer als CSV</Link>
      <Table variant="simple">
        <Thead>
          <Tr>
            {fields.data.map((value: RegistrationField, index: number) => {
              return <Th key={value.id}>{value.fieldName}</Th>
            })}
            <Th>Datum</Th>
            <Th>Uur</Th>
            <Th>Stuur herinnering</Th>
            <Th>Verwijder</Th>
          </Tr>
        </Thead>
        <Tbody>
          {renderRegistrations()}
        </Tbody>
      </Table>
      <AlertDialog
        isOpen={sendReminderIsOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setSendReminderIsOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Verstuur herinnerings email
            </AlertDialogHeader>

            <AlertDialogBody>
              Deze actie zal een herinneringsmail versturen naar volgend emailadres: {selectedRegistration?.fields["email"]}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setSendReminderIsOpen(false)}>
                Annuleer
              </Button>
              <Button colorScheme="orange" onClick={() => {
                  sendRegistrationReminder(selectedRegistration!.id);
                  setSendReminderIsOpen(false)}
                  } ml={3}>
                Verstuur
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog
        isOpen={deleteRegistrationIsOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setDeleteRegistrationIsOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Verwijder deze registratie
            </AlertDialogHeader>

            <AlertDialogBody>
              Volgende registratie zal verwijderd worden: {selectedRegistration?.fields["email"]}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setDeleteRegistrationIsOpen(false)}>
                Annuleer
              </Button>
              <Button colorScheme="red" onClick={() => {
                  deleteRegistrationMutation.mutate(selectedRegistration!.id);
                  setDeleteRegistrationIsOpen(false);
                    }
                  } ml={3}>
                Verwijder
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  )

}