import { GenericForm } from "../models/GenericForm";
import { RegistrationField } from "../models/RegistrationField";
import { Submission } from "../models/Submission";

export async function fetchForms(organizationId: number | null): Promise<GenericForm[]> {
  var queryParams = ""
  if (organizationId !== null) {
    queryParams = "?organizationId=" + organizationId;
  } else {
    return new Promise(() => []);
  }
  const apiUrl = '/api/forms' + queryParams;
  const response = await fetch(apiUrl, {
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": "true"
    }
  });
  if (!response.ok) {
    throw new Error('FetchForms was not ok');
  }
  return response.json();
}

export async function addForm(formName: string, organizationId: number | null): Promise<GenericForm> {
  const response = await fetch('/api/forms', {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      name: formName,
      OrganizationId: organizationId,
    })
  })
  if (!response.ok) {
    throw new Error("addForm was not ok");
  }
  return response.json();
}

export async function fetchFormFields(formId: number): Promise<RegistrationField[]> {
  const apiUrl = `/api/forms/${formId}/fields`;
  const response = await fetch(apiUrl, { credentials: 'include', })
  if (!response.ok) {
    throw new Error("fetchFormFields was not ok")
  }
  return response.json();
}

export async function addFormField(formId: number, field: RegistrationField): Promise<RegistrationField> {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' as "include",
    body: JSON.stringify(field),
  };
  const response = await fetch(`/api/forms/${formId}/fields`, requestOptions);
  return response.json()
}

export async function updateFields(formId: number, fields: RegistrationField[]): Promise<RegistrationField[]> {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' as "include",
    body: JSON.stringify(fields),
  };
  const response = await fetch(`/api/forms/${formId}/fields`, requestOptions);
  return response.json()
}

export async function deleteFormField(formId: number, fieldId: number): Promise<Response> {
  const requestOptions = {
    method: 'DELETE',
    credentials: 'include' as "include",
  };
  const response = await fetch(`/api/events/${formId}/fields/${fieldId}`, requestOptions);
  if (!response.ok) {
    throw new Error("deleteFormField was not ok")
  }
  return response
}

export async function fetchSubmissions(formId: number): Promise<Submission[]> {
  const apiUrl = `/api/forms/${formId}/submissions`;
  const response = await fetch(apiUrl, { credentials: 'include' });
  if (!response.ok) {
    throw new Error("fetchSubmissions was not ok");
  }
  return response.json();
}

export async function deleteSubmission(formId: number, submissionId: number): Promise<Response> {
  const requestOptions: RequestInit = {
    method: 'DELETE',
    credentials: 'include',
  };
  const apiUrl = `/api/forms/${formId}/submissions/${submissionId}`;
  const response = await fetch(apiUrl, requestOptions);
  if (!response.ok) {
    throw new Error("deleteSubmission was not ok");
  }
  return response;
}