import { ChevronDownIcon } from "@chakra-ui/icons"
import { Button, Center, Input, Menu, MenuButton, MenuGroup, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react"
import React, { useContext, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { addOrganization, fetchOrganizations } from "../api/OrganizationsApi"
import { EventContext } from "../App"
import { Organization } from "../models/Organization"

export const OrganizationsMenu: React.FunctionComponent<{}> = () => {

  const [newOrganizationName, setOrganizationName] = useState("");
  const [newOrganizationSite, setOrganizationSite] = useState("");

  const queryClient = useQueryClient();
  const { state, dispatch } = useContext(EventContext);
  const { data: organizations } = useQuery<Organization[]>(["organizations"], fetchOrganizations, {
    enabled: (state.currentUser !== null && state.currentUser.authenticated),
  });
  const addOrganizationMutation = useMutation(() => addOrganization({ name: newOrganizationName, site: newOrganizationSite }), {
    onSuccess: () => {
      queryClient.invalidateQueries(["organizations"]);
      setOrganizationName("");
      setOrganizationSite("");
    }
  })


  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOrganization = (organization: Organization) => {
    queryClient.invalidateQueries('events');
    dispatch({
      type: "SELECT_ORGANIZATION",
      payload: {
        selectedOrganization: organization
      }
    });
  }

  return (
    <>
      <Menu>
        <MenuButton  w="80%" as={Button} rightIcon={<ChevronDownIcon />}
          px={4}
          py={2}
          transition="all 0.2s"
          borderRadius="md"
          borderWidth="1px"
          colorScheme="button"
          _focus={{ boxShadow: "outline" }}>
          {state.selectedOrganization ? state.selectedOrganization.name : "Organisaties"}
        </MenuButton>
        <MenuList>
          <MenuGroup title="Organisaties">
            {organizations === undefined ?
              <Center></Center>
              :
              organizations.map((value: Organization, _) => {
                return (
                  <MenuItem key={value.id}
                    onClick={() => handleOrganization(value)}>{value.name}</MenuItem>
                )
              })}
          </MenuGroup>
          <MenuGroup title="Nieuw">
            <MenuItem onClick={onOpen}>Nieuwe organisatie toevoegen</MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Organisatie toevoegen</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input placeholder="Organisatie naam" type='text' name='newOrganizationName' onChange={(e) => setOrganizationName(e.target.value)} value={newOrganizationName}></Input>
            <Input placeholder="Organisatie website" type='text' name='newOrganizationSite' onChange={(e) => setOrganizationSite(e.target.value)} value={newOrganizationSite}></Input>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => addOrganizationMutation.mutate()} colorScheme="button">Toevoegen</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}