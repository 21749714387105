import { Box, Button } from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

export const AddEvent: React.FunctionComponent<{ organizationId: number | null }> = ({ organizationId }) => {

  return (
    <Box>
      <RouterLink to="/new">
        <Button w="100%" h="75px" colorScheme="button">
          Nieuw
        </Button>
      </RouterLink>
    </Box>
  )
}