import { Box, Button, Flex, NumberInput, NumberInputField, Text, Textarea } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";

export const EventMail: React.FunctionComponent<{ eventId: number }> = (props) => {

  const [emailTemplate, setEmailTemplate] = useState('');
  const [reminderEmailTemplate, setReminderEmailTemplate] = useState('');
  const [daysBefore, setDaysBefore] = useState(1);

  const fetchMailTemplates = useCallback(() => {
    const apiUrl = `/api/events/${props.eventId}`;
    fetch(apiUrl, { credentials: 'include' })
      .then((response) => response.json())
      .then((data) => {
        setEmailTemplate(data.emailTemplate);
        setReminderEmailTemplate(data.reminderEmailTemplate);
        setDaysBefore(data.reminderEmailDaysBefore);
      });
  }, [props.eventId]);

  useEffect(() => { fetchMailTemplates() }, [fetchMailTemplates])

  const submitTemplate = () => {
    const requestOptions = {
      method: 'PUT',
      credentials: 'include' as "include",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ emailTemplate: emailTemplate, reminderEmailTemplate: reminderEmailTemplate, reminderEmailDaysBefore: daysBefore })
    };
    fetch(`/api/events/${props.eventId}`, requestOptions)
      .then(response => response.json());
  }

  const sendReminders = () => {
    const requestOptions = {
      method: 'POST',
      credentials: 'include' as "include",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({})
    };
    fetch(`/api/registrations/${props.eventId}/reminders`, requestOptions)
      .then(response => response.json());
  }

  return (
    <Box>
      <Text fontSize={24} as="h2">Email template</Text>
      <Flex>
        <Box w="500px">
          <Textarea w="400px" h="400px" onChange={(e) => setEmailTemplate(e.target.value)} value={emailTemplate} placeholder="Email template" />
        </Box>
        <Box w="500px">
          <Textarea w="400px" h="400px" onChange={(e) => setReminderEmailTemplate(e.target.value)} value={reminderEmailTemplate} placeholder="Reminder Email template" />
        </Box>
      </Flex>
      <Flex justify="space-between">
        <Box>
          <Text as="h3">Dagen voor herinnering</Text>
          <NumberInput value={daysBefore} onChange={(e) => setDaysBefore(parseInt(e))}>
            <NumberInputField />
          </NumberInput>
        </Box>
        <Box>
          <Button onClick={() => sendReminders()}>Stuur naar iedereen</Button>
        </Box>
      </Flex>
      <Box>
        <Button onClick={() => submitTemplate()}>Opslaan</Button>
      </Box>
    </Box>
  )
}