import { extendTheme } from "@chakra-ui/react";
import { styles } from "./global";
import { EventsList } from "./components/EventsList";
import { SideBar } from "./components/SideBar";
import { EventFields } from "./components/EventFields";
import { EventDates } from "./components/EventDates";

const overrides = {
  initialColorMode: "light",
  useSystemColorMode: false,
  colors: {
    "button": {
      "50": "#EEF3F7",
      "100": "#CFDEE8",
      "200": "#AFC9D9",
      "300": "#90B4CB",
      "400": "#719FBC",
      "500": "#528AAD",
      "600": "#426E8A",
      "700": "#315368",
      "800": "#213745",
      "900": "#101C23"
    }
  },
  styles,
  components: {
    EventsList,
    SideBar,
    EventFields,
    EventDates,
  },
}


export default extendTheme(overrides)