export const EventFields = {
  parts: ["table"],
  baseStyle: {
    table: {
      tableLayout: "fixed",
    },
  },
  sizes: {},
  variants: {},
  defaultProps: {},
}