import { ChevronRightIcon } from "@chakra-ui/icons";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Flex, FormControl, FormLabel, forwardRef, Input, ListItem, Text, UnorderedList, useMultiStyleConfig } from "@chakra-ui/react";
import add from "date-fns/add";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { addEventDate, addTimeSlot, deleteDateSlot, fetchEventDates } from "../../api/EventsApi";
import { DaySlot } from "../../models/DaySlot";
import { TimeSlotsTable } from "../TimeSlotsTable";

export const EventDates: React.FunctionComponent<{ eventId: number }> = ({ eventId }) => {
  const [date, setDate] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState<DaySlot | null>(null);
  const [timeSlot, setTimeSlot] = useState('');
  const [capacity, setCapacity] = useState(0);
  const [link, setLink] = useState('');
  const [bulkInterval, setBulkInterval] = useState(0);
  const [bulkStart, setBulkStart] = useState('');
  const [bulkEnd, setBulkEnd] = useState('')
  const [bulkCapacity, setBulkCapacity] = useState(1)
  const styles = useMultiStyleConfig("EventDates", {});

  const queryClient = useQueryClient()

  const eventDates = useQuery<DaySlot[], Error>(["eventDates", eventId], () => fetchEventDates(eventId));

  const addDate = useMutation(() => addEventDate(eventId, date), {
    onSuccess: () => {
      queryClient.invalidateQueries(["eventDates", eventId]);
      setDate(new Date());
    }
  });

  const addTimeSlotMutation = useMutation(() => addTimeSlot(eventId, selectedDay as DaySlot, timeSlot, capacity, link), {
    onSuccess: () => {
      queryClient.invalidateQueries(["timeslots", selectedDay!.id]);
      resetFormStates();
    }
  });

  const deleteDateSlotMutation = useMutation((dateSlotId: number) => deleteDateSlot(eventId, dateSlotId), {
    onSuccess: () => {
      queryClient.invalidateQueries(["eventDates", eventId])
      setSelectedDay(null);;
    }
  });

  const CustomDateInput = forwardRef(
    ({ value, onClick }, ref) => (
      <Button colorScheme="button" onClick={onClick} ref={ref}>
        {value}
      </Button>
    ),
  );

  useEffect(() => {
    setSelectedDay(null);
  }, [eventId]);

  const selectDay = (day: DaySlot) => {
    setSelectedDay(day);
  }

  const resetFormStates = () => {
    setTimeSlot('');
    setCapacity(0);
    setLink('');
  }

  const bulkAddTimeSlots = () => {
    var bulkStartArray = bulkStart.split(':')
    var currentDate = new Date(selectedDay?.date.getTime() as number)
    currentDate?.setHours(parseInt(bulkStartArray[0]), parseInt(bulkStartArray[1]))
    var bulkEndArray = bulkEnd.split(':')
    var endDate = new Date(selectedDay?.date.getTime() as number)
    endDate?.setHours(parseInt(bulkEndArray[0]), parseInt(bulkEndArray[1]))
    var promises = []
    while (currentDate! <= endDate!) {
      const requestOptions = {
        method: 'POST',
        credentials: 'include' as 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          time: currentDate,
          capacity: bulkCapacity,
        })
      };
      promises.push(fetch(`/api/events/${eventId}/dayslots/${selectedDay!.id}/timeslots`, requestOptions));
      currentDate = add(currentDate!, { minutes: bulkInterval });
    }
    Promise.all(promises).then(() => queryClient.invalidateQueries(["eventDates", eventId]))
  }

  return (
    <Flex>
      <Box w="25%">
        <Text fontSize={24} as="h2">Datums</Text>
        <UnorderedList sx={styles.list}>
          {eventDates.data && (eventDates.data).map((value: DaySlot, index: number) => {
            return (
              <ListItem _selected={{ bg: "blue.700" }} aria-selected={selectedDay !== null && value.id === selectedDay.id} sx={styles.listitem} cursor="pointer" onClick={() => selectDay(value)} key={index}>{value.date.toLocaleDateString()}
                <ChevronRightIcon boxSize={6} float="right"></ChevronRightIcon>
              </ListItem>)
          })}
        </UnorderedList>
        <Box>
          <Text as="h2">Nieuwe datum toevoegen</Text>
          <Box>
            <Text>Kies datum: </Text>
            <DatePicker selected={date} onChange={date => setDate(date as Date)} customInput={<CustomDateInput />}/>
          </Box>
          <Box pt="10px">
            <Button onClick={() => addDate.mutate()}>Toevoegen</Button>
          </Box>
        </Box>
      </Box>
      { selectedDay != null &&
        <Box w="60%" ml="30px" pl="30px" borderLeft="1px" borderStyle="solid" borderColor="gray.300">
          <Accordion allowToggle allowMultiple>
            <Box>
              <AccordionItem>
                <Text as="h2"><AccordionButton>Tijdslots<AccordionIcon /></AccordionButton></Text>
                <AccordionPanel>
                  <TimeSlotsTable daySlotId={selectedDay.id}></TimeSlotsTable>
                </AccordionPanel>
              </AccordionItem>
            </Box>
            <Box>
              <AccordionItem>
                <Text as="h2"><AccordionButton>Nieuw tijdslot<AccordionIcon /></AccordionButton></Text>
                <AccordionPanel>
                  <Box>
                    <Box display={{ md: "flex" }}>
                      <FormControl w="50%">
                        <FormLabel>Uur</FormLabel>
                        <Input placeholder="12:00" type='text' name='timeSlot' onChange={(e) => setTimeSlot(e.target.value)} value={timeSlot} w="150px"></Input>
                      </FormControl>
                      <FormControl w="50%">
                        <FormLabel>Capaciteit</FormLabel>
                        <Input placeholder="capaciteit" type='number' name='capacity' onChange={(e) => setCapacity(parseInt(e.target.value))} value={capacity} w="150px"></Input>
                      </FormControl>
                    </Box>
                    <Box display={{ md: "flex" }}>
                      <FormControl w="50%">
                        <FormLabel>Link</FormLabel>
                        <Input placeholder="link" type="text" name="link" onChange={(e) => setLink(e.target.value)} value={link} w="150px"></Input>
                      </FormControl>
                      <FormControl w="50%" >
                        <FormLabel>Toevoegen</FormLabel>
                        <Button onClick={() => addTimeSlotMutation.mutate()}>Nieuw tijdslot</Button>
                      </FormControl>
                    </Box>
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            </Box>
            <Box>
              <AccordionItem>
                <Text as="h2"><AccordionButton>Bulk toevoegen<AccordionIcon /></AccordionButton></Text>
                <AccordionPanel>
                  <Box display={{ md: "flex" }}>
                    <FormControl w="50%">
                      <FormLabel>Elke X minuten</FormLabel>
                      <Input placeholder="Elke X minuten" type='number' name='bulkInterval' onChange={(e) => setBulkInterval(parseInt(e.target.value))} value={bulkInterval} w="150px"></Input>
                    </FormControl>
                    <FormControl w="50%">
                      <FormLabel>Starttijd</FormLabel>
                      <Input placeholder="Van" type='text' name='bulkStart' onChange={(e) => setBulkStart(e.target.value)} value={bulkStart} w="150px"></Input>
                    </FormControl>
                  </Box>
                  <Box display={{ md: "flex" }}>
                    <FormControl w="50%">
                      <FormLabel>Eindtijd</FormLabel>
                      <Input placeholder="Tot" type="text" name="bulkEnd" onChange={(e) => setBulkEnd(e.target.value)} value={bulkEnd} w="150px"></Input>
                    </FormControl>
                    <FormControl w="50%">
                      <FormLabel>Capaciteit</FormLabel>
                      <Input placeholder="Tot" type="number" name="bulkCapacity" onChange={(e) => setBulkCapacity(Number(e.target.value))} value={bulkCapacity} w="150px"></Input>
                    </FormControl>
                  </Box>
                  <Box display={{ md: "flex" }}>
                  <FormControl w="50%" >
                      <FormLabel>Toevoegen</FormLabel>
                      <Button onClick={() => bulkAddTimeSlots()}>toevoegen</Button>
                    </FormControl>
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            </Box>
            <Box>
              <AccordionItem>
                <Text as="h2"><AccordionButton>Verwijderen<AccordionIcon /></AccordionButton></Text>
                <AccordionPanel>
                  <Button colorScheme="red" onClick={() => deleteDateSlotMutation.mutate(selectedDay!.id)}>Verwijder datum</Button>
                </AccordionPanel>
              </AccordionItem>
            </Box>
          </Accordion>
        </Box>
      }
    </Flex >
  )


}