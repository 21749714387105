import { ChevronRightIcon } from "@chakra-ui/icons";
import { Center, ListItem, Spinner, UnorderedList, useMultiStyleConfig } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { fetchForms } from "../api/GenericFormsApi";
import { EventContext } from "../App";
import { GenericForm } from "../models/GenericForm";

export const FormsList: React.FunctionComponent<{ organizationId: number | null }> = (props) => {

  const { state, dispatch } = useContext(EventContext);
  const { status, data, error } = useQuery<GenericForm[], Error>(['forms', props.organizationId], () => fetchForms(props.organizationId), { refetchOnMount: "always" });
  const styles = useMultiStyleConfig("EventsList", {});

  if (!props.organizationId) {
    return (
      <></>
    )
  }

  return (
    <>
      {status === "loading" ? (
        <Center><Spinner></Spinner></Center>
      ) : status === "error" ? (
        <span>Error: {error!.message}</span>
      ) : (
        <>
        <UnorderedList sx={styles.list}>
          {data !== undefined && (data).map((value: GenericForm, index: number) => {
            return <Link to="/form" key={value.id}>
              <ListItem sx={styles.listitem} aria-selected={state.selectedForm !== null && state.selectedForm!.id === value.id}
                onClick={() => { dispatch({ type: "SELECT_FORM", payload: { selectedForm: value } }) }}>
                {value.name}<ChevronRightIcon boxSize={6} float="right" />
              </ListItem></Link>
          })}
        </UnorderedList>
        </>
      )}
    </>
  )

}