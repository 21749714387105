import { User } from "../models/User";

export async function getCurrentUser(): Promise<User> {
  const authUrl = '/auth/user';
  const response = await fetch(authUrl, {
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": "true"
    }
  })
  if (!response.ok) {
    throw new Error("getCurrentUser fetch failed");
  }
  return response.json()
}