import { Organization, OrganizationBody } from "../models/Organization";

export async function fetchOrganizations(): Promise<Organization[]> {
  const apiUrl = '/api/organizations';
  const response = await fetch(apiUrl, {
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": "true"
    }
  })
  if (!response.ok) {
    throw new Error("fetOrganizations failed");
  }
  return response.json();
}

export async function addOrganization(organization: OrganizationBody): Promise<Organization> {
  const requestOptions = {
    method: 'POST',
    credentials: 'include' as 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(organization)
  };
  const response = await fetch(`/api/organizations`, requestOptions);
  if (!response.ok) {
    throw new Error("addOrganization was not succesfull");
  }
  return response.json();
    
}