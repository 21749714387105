import { DaySlot } from "../models/DaySlot";
import { EventSettingsModel } from "../models/EventSettingsModel";
import { RegistrationEvent } from "../models/RegistrationEvent";
import { RegistrationField } from "../models/RegistrationField";
import { TimeSlot } from "../models/TimeSlot";

export async function fetchEventDates(eventId: number): Promise<DaySlot[]> {
  const apiUrl = `/api/events/${eventId}/dayslots`;
  const response = await fetch(apiUrl, { credentials: 'include' });
  if (!response.ok) {
    throw new Error("FetchEventDates was not ok")
  }
  return response.json()
    .then<DaySlot[]>((data: DaySlot[]) => data.map((e: DaySlot) => { return { ...e, date: new Date(e.date) } }));
}

export async function fetchEventFields(eventId: number): Promise<RegistrationField[]> {
  const apiUrl = `/api/events/${eventId}/fields`;
  const response = await fetch(apiUrl, { credentials: 'include', })
  if (!response.ok) {
    throw new Error("FetchEventFields was not ok")
  }
  return response.json();
}

export async function fetchEventSettings(eventId: number): Promise<EventSettingsModel> {
  const apiUrl = `/api/events/${eventId}/settings`;
  const response = await fetch(apiUrl, { credentials: 'include', })
  if (!response.ok) {
    throw new Error("FetchEventSettings was not ok")
  }
  return response.json();
}

export async function fetchTimeSlots(eventId: number, daySlotId: number): Promise<TimeSlot[]> {
  const response = await fetch(`/api/events/${eventId}/dayslots/${daySlotId}/timeslots`, { credentials: 'include', })
  if (!response.ok) {
    throw new Error("FetchTimeSlots was not ok");
  }
  return response.json().then(data => data.sort((a: TimeSlot, b: TimeSlot) => new Date(a.time).getTime() - new Date(b.time).getTime()));
}

export async function addTimeSlot(eventId: number, daySlot: DaySlot, timeSlot: string, capacity: number, link: string): Promise<TimeSlot> {
  const [hours, minutes] = timeSlot.split(":");
  const requestOptions = {
    method: 'POST',
    credentials: 'include' as 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      time: daySlot.date.setHours(parseInt(hours), parseInt(minutes)),
      capacity: capacity,
      link: link
    })
  };
  const response = await fetch(`/api/events/${eventId}/dayslots/${daySlot.id}/timeslots`, requestOptions)
  return response.json()
}

export async function updateTimeSlot(eventId: number, timeSlotId: number, capacity: number): Promise<TimeSlot> {
  const requestOptions = {
    method: 'PUT',
    credentials: 'include' as 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      capacity: capacity
    })
  };
  const response = await fetch(`/api/events/${eventId}/timeslots/${timeSlotId}`, requestOptions)
  return response.json()
}

export async function deleteDateSlot(eventId: number, daySlotId: number): Promise<Response> {
  const requestOptions = {
    method: 'DELETE',
    credentials: 'include' as "include",
  };
  const response = await fetch(`/api/events/${eventId}/dayslots/${daySlotId}`, requestOptions)
  if (!response.ok) {
    throw new Error("deleteTimeSlot was not ok");
  }
  return response
}


export async function deleteTimeSlot(eventId: number, timeSlotId: number): Promise<Response> {
  const requestOptions = {
    method: 'DELETE',
    credentials: 'include' as "include",
  };
  const response = await fetch(`/api/events/${eventId}/timeslots/${timeSlotId}`, requestOptions)
  if (!response.ok) {
    throw new Error("deleteTimeSlot was not ok");
  }
  return response
}

export async function addEventField(eventId: number, field: RegistrationField): Promise<RegistrationField> {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' as "include",
    body: JSON.stringify(field),
  };
  const response = await fetch(`/api/events/${eventId}/fields`, requestOptions);
  return response.json()
}

export async function updateFields(eventId: number, fields: RegistrationField[]): Promise<RegistrationField[]> {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' as "include",
    body: JSON.stringify(fields),
  };
  const response = await fetch(`/api/events/${eventId}/fields`, requestOptions);
  return response.json()
}

export async function deleteEventField(eventId: number, fieldId: number): Promise<Response> {
  const requestOptions = {
    method: 'DELETE',
    credentials: 'include' as "include",
  };
  const response = await fetch(`/api/events/${eventId}/fields/${fieldId}`, requestOptions);
  if (!response.ok) {
    throw new Error("deleteEventField was not ok")
  }
  return response
}

export async function addEvent(eventName: string, organizationId: number | null): Promise<RegistrationEvent> {
  const response = await fetch('/api/events', {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      name: eventName,
      OrganizationId: organizationId,
    })
  })
  if (!response.ok) {
    throw new Error("AddEvent was not ok");
  }
  return response.json();
}

export async function addEventDate(eventId: number, date: Date): Promise<DaySlot> {
  const requestOptions = {
    method: 'POST',
    credentials: 'include' as any,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ date: date })
  };
  const response = await fetch(`/api/events/${eventId}/dayslots`, requestOptions)
  if (!response.ok) {
    throw new Error("addEventDate was not ok")
  }
  return response.json();
}

export async function fetchEvents(organizationId: number | null): Promise<RegistrationEvent[]> {
  var queryParams = ""
  if (organizationId !== null) {
    queryParams = "?organizationId=" + organizationId;
  } else {
    return new Promise(() => []);
  }
  const apiUrl = '/api/events' + queryParams;
  const response = await fetch(apiUrl, {
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": "true"
    }
  });
  if (!response.ok) {
    throw new Error('FetchEvents was not ok');
  }
  return response.json();
}

export async function deleteEvent(eventId: number): Promise<Response> {
  const requestOptions = {
    method: 'DELETE',
    credentials: 'include' as "include",
  };
  const response = await fetch(`/api/events/${eventId}`, requestOptions);
  if (!response.ok) {
    throw new Error("deleteEvent was not ok")
  }
  return response
}
