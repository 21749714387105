import { CopyIcon, DeleteIcon, DownloadIcon } from "@chakra-ui/icons";
import { Box, Button, Center, Flex, Link, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Spinner, Switch, Table, Tbody, Td, Text, Th, Thead, Tr, UnorderedList, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useQuery } from "react-query";
import { deleteSubmission, fetchFormFields, fetchSubmissions } from "../../api/GenericFormsApi";
import { RegistrationField } from "../../models/RegistrationField";
import { Submission } from "../../models/Submission";


export const FormSubmissions: React.FunctionComponent<{ formId: number }> = ({ formId }) => {
  const queryClient = useQueryClient();
  const [deleteMode, toggleDeleteMode] = useState(false);
  const [selectedSubmission, setSelectedSubmission] = useState<Submission | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const fields = useQuery<RegistrationField[], Error>(["formfields", formId], () => fetchFormFields(formId));
  const submissions = useQuery<Submission[], Error>(
    ["submissions", formId], () => fetchSubmissions(formId)
  );

  const deleteSubmissionMutation = useMutation((submissionId: number) => deleteSubmission(formId, submissionId), {
    onSuccess: () => {
      queryClient.invalidateQueries(["submissions", formId]);
      onClose();
    }
  })

  if (fields.status === "error" || submissions.status === "error") {
    return (
      <Center>Couldn`t fetch fields or registrations</Center>
    )
  }

  const renderSubmissions = () => {
    if (submissions.status === "loading") {
      return (
        <Tr><Td><Spinner /></Td></Tr>
      )
    }
    if (submissions.data!.length > 0) {
      return submissions.data!.map((submission: Submission, index: number) => {
          return (<Tr key={`tr${submission.id}`}>
            {fields.data!.map((field: RegistrationField, index: number) => {
              if (submission.fields) {
                if (field.fieldType === "file") {
                  return (<Td key={`td${field.id}`}>
                    <Link href={submission.fields[field.fieldName]}>
                      <DownloadIcon cursor="pointer">
                      </DownloadIcon>
                    </Link>
                  </Td>)
                } else {
                return (
                  <Td key={`td${field.id}`}>
                    {submission.fields[field.fieldName]}
                  </Td>)
                }
              } else {
                return (<Td key={`td${field.id}`}></ Td>)
              }
            })}
            <Td>{new Date(submission.createdAt).toLocaleDateString()}</Td>
            <Td>{new Date(submission.createdAt).toLocaleTimeString()}</Td>
            {deleteMode && (
              <Td><DeleteIcon cursor="pointer" onClick={() => {setSelectedSubmission(submission); onOpen()}}></DeleteIcon></Td>
            )}
          </Tr>)

      })
    } else {
      return <Tr><Td>Nog geen registraties</Td></Tr>
    }
  }

  if (!fields.data) {
    return (
      <Center>Couldn`t fetch fields</Center>
    )
  }

  return (
    <Box>
      <Flex>
      <Text fontSize={24} as="h2">Inzendingen</Text>
      <Spacer/>
      <Box m="auto">
        <Text>Verwijder modus:</Text><Switch isChecked={deleteMode} onChange={(e) => toggleDeleteMode(e.target.checked)} />
      </Box>
      </Flex>
      <Link href={`/api/forms/${formId}/submissions/csv`} isExternal><CopyIcon />Exporteer als CSV</Link>
      <Table variant="simple">
        <Thead>
          <Tr>
            {fields.data.map((value: RegistrationField, index: number) => {
              return <Th key={value.id}>{value.fieldName}</Th>
            })}
            <Th>datum</Th>
            <Th>uur</Th>
            {deleteMode && (
              <Th>Verwijderen</Th>
            )}
          </Tr>
        </Thead>
        <Tbody>
          {renderSubmissions()}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Inzending verwijderen</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Volgende inzending zal worden verwijderd:</Text>
            <UnorderedList>        
            {selectedSubmission && Object.entries(selectedSubmission.fields).map((key: string | unknown, value: string | unknown) => {
              return (
                <ListItem>{key} : {value}</ListItem>
              )
            })}
            </UnorderedList>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={() => deleteSubmissionMutation.mutate(selectedSubmission!.id)}>
              Verwijderen
            </Button>
            <Button variant="ghost">Annuleren</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )

}