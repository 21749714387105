import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useColorModeValue } from "@chakra-ui/react";
import React, { useContext } from "react";
import { EventContext } from "../../App";
import { FormFields } from "./FormFields";
import { FormMail } from "./FormMail";
import { FormSettings } from "./FormSettings";
import { FormSubmissions } from "./FormSubmissions";
import { FormUsage } from "./FormUsage";


export const FormDetails: React.FunctionComponent<{}> = (props) => {

  const { state } = useContext(EventContext);
  const tabColor = useColorModeValue("gray.600", "whiteAlpha.900");

  if (state.selectedForm == null) {
    return (<Box p="6">Kies een formulier</Box>)
  }
  return (
    <>
    <Box p="6">
      <Box pb="10">
        <Text fontSize={40} as="h1">{state.selectedForm.name}</Text>
        <Text fontSize={12}>ID: {state.selectedForm.id}</Text>
      </Box>
      <Tabs variant="soft-rounded" colorScheme="button">
      <TabList>
        <Tab color={tabColor}>Velden</Tab>
        <Tab color={tabColor}>Inzendingen</Tab>
        <Tab color={tabColor}>Emails</Tab>
        <Tab color={tabColor}>Gebruik</Tab>
        <Tab color={tabColor}>Instellingen</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <FormFields form={state.selectedForm}></FormFields>
        </TabPanel>
        <TabPanel>
          <FormSubmissions formId={state.selectedForm.id}></FormSubmissions>
        </TabPanel>
        <TabPanel>
          <FormMail formId={state.selectedForm.id}></FormMail>
        </TabPanel>
        <TabPanel>
          <FormUsage formId={state.selectedForm.id}></FormUsage>
        </TabPanel>
        <TabPanel>
          <FormSettings formId={state.selectedForm.id}></FormSettings>
        </TabPanel>
      </TabPanels>
    </Tabs>
    </Box>
  </>
  );

}