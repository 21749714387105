import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import { Box, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Link, ListItem, Switch, UnorderedList, useColorMode } from "@chakra-ui/react";
import React, { useContext } from "react";
import { EventContext } from "../App";

export const SettingsDrawer: React.FunctionComponent<{ isOpen: boolean, onOpen: () => void, onClose: () => void }> = ({ isOpen, onOpen, onClose }) => {

  const logoutHandler = () => {
    fetch("/auth/logout", { method: 'GET' })
      .then(response => {
        if (response.redirected) {
          window.location.href = response.url;
        }
      })
      .catch(function (err) {
        throw new Error("Couldn't logout");;
      });
  }

  const { colorMode, toggleColorMode } = useColorMode()
  const { state } = useContext(EventContext);

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Welkom {state.currentUser.username}</DrawerHeader>
            <DrawerBody>
              <UnorderedList listStyleType="none">
                <ListItem>
                <Link>Profiel</Link>
                </ListItem>
                <ListItem>
                  <Link onClick={logoutHandler}>
                    Log uit
                  </Link>
                </ListItem>
              </UnorderedList>
            </DrawerBody>

            <DrawerFooter justifyContent="start">
              <Box>
                <SunIcon /> <Switch isChecked={colorMode !== "light"} onChange={toggleColorMode} id="color-mode" /> <MoonIcon />
              </Box>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  )
}