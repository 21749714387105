import { Box, Button, Center, Container, Heading, HStack, Icon, Input, Stack, StackDivider, Text, useColorModeValue, useRadio, useRadioGroup, UseRadioProps } from "@chakra-ui/react"
import React, { useContext, useState } from "react"
import { IconType } from "react-icons"
import { AiOutlineForm } from 'react-icons/ai'
import { BiCalendarCheck } from 'react-icons/bi'
import { useMutation, useQueryClient } from "react-query"
import { useHistory } from "react-router-dom"
import { addEvent } from "../api/EventsApi"
import { addForm } from "../api/GenericFormsApi"
import { EventContext } from "../App"

const RadioCard: React.FunctionComponent<UseRadioProps> = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  const buttonColor = useColorModeValue("button.500", "button.200")
  const textColor = useColorModeValue("white", "gray.800")

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: buttonColor,
          color: textColor,
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  )
}

type ItemRadioProps = {
  name: string
  description: string
  icon: IconType
}

export const NewItem: React.FunctionComponent<{}> = () => {
  const [type, setType] = useState("Evenement")
  const options = [
    {
      name: "Evenement",
      description: "Maak een nieuw evenement",
      icon: BiCalendarCheck,
    },
    {
      name: "Formulier",
      description: "Maak een nieuw formulier",
      icon: AiOutlineForm,
    },
  ]

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "itemType",
    defaultValue: "Evenement",
    onChange: setType
  })

  const group = getRootProps()

  const [newName, setNewName] = useState('');
  const newNameChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value;
    setNewName(newName);
  }

  const queryClient = useQueryClient();
  const { state, dispatch } = useContext(EventContext);

  const history = useHistory();

  const addEventMutation = useMutation((eventName: string) => addEvent(eventName, state.selectedOrganization!.id), {
    onSuccess: (value) => {
      setNewName('');
      queryClient.invalidateQueries(["events", state.selectedOrganization!.id]);
      dispatch({ type: "SELECT_EVENT", payload: { selectedEvent: value } })
      history.push('/event')

    }
  });

  const addFormMutation = useMutation((formName: string) => addForm(formName, state.selectedOrganization!.id), {
    onSuccess: (value) => {
      setNewName('');
      queryClient.invalidateQueries(["forms", state.selectedOrganization!.id]);
      dispatch({ type: "SELECT_FORM", payload: { selectedForm: value } })
      history.push('/form')
    }
  });

  const submitNewItem = () => {
    if (type === "Evenement") {
      addEventMutation.mutate(newName);
    } else {
      addFormMutation.mutate(newName);
    }
  }

  return (
    <Box p="6">
      <Center><Text as="h1">Voeg een nieuw item toe</Text></Center>
      <Container mt="20px">
        <Heading as="h2">1 - Kies type</Heading>
        <Stack mt="20px" divider={<StackDivider borderColor="gray.200" />}
          spacing={4}>
          <HStack {...group} align="center" justify="center">
            {options.map((itemOption: ItemRadioProps) => {
              const radio = getRadioProps({ value: itemOption.name })
              return (
                <RadioCard key={itemOption.name} {...radio}>
                  <HStack spacing={8}>
                    <Center w="30%">
                      <Icon w={16} h={16} as={itemOption.icon}></Icon>
                    </Center>
                    <Box w="70%">
                      <Heading as="h2">{itemOption.name}</Heading>
                      <Text>{itemOption.description}</Text>
                    </Box>
                  </HStack>
                </RadioCard>
              )
            })}
          </HStack>
        </Stack>
        <Heading mt="20px" as="h2">2 - Kies naam</Heading>
        <Input mt="10px" type="text" placeholder="Naam"
          name='new_name' value={newName}
          onChange={newNameChangeHandler}></Input>
        <Button mt="20px" size="lg" colorScheme="button" onClick={submitNewItem}>Submit</Button>
      </Container>
    </Box>
  )
}
