import { DeleteIcon } from "@chakra-ui/icons"
import { Center, Editable, EditableInput, EditablePreview, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react"
import React, { useContext } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { StringMappingType } from "typescript"
import { deleteTimeSlot, fetchTimeSlots, updateTimeSlot } from "../api/EventsApi"
import { EventContext } from "../App"
import { TimeSlot } from "../models/TimeSlot"

export const TimeSlotsTable: React.FunctionComponent<{ daySlotId: number }> = ({ daySlotId }) => {

  const { state } = useContext(EventContext);

  const queryClient = useQueryClient();
  const timeSlots = useQuery<TimeSlot[], Error>(["timeslots", daySlotId], () => fetchTimeSlots(state.selectedEvent!.id, daySlotId));

  const deleteTimeSlotMutation = useMutation((timeSlotId: number) => deleteTimeSlot(state.selectedEvent!.id, timeSlotId), {
    onSuccess: () => {
      queryClient.invalidateQueries(["timeslots", daySlotId])
    }
  });

  const updateCapacity = (nextValue: string, timeSlotId: number) => {
    updateTimeSlot(state.selectedEvent?.id as number, timeSlotId, Number(nextValue))
  }

  if (timeSlots.status === "loading") {
    return (
      <Center><Spinner></Spinner></Center>
    )
  } else if (timeSlots.status === "error") {
    return (
      <Text>Failed to load timeslots</Text>
    )
  }
  return (
    <Table variant="simple" size="sm">
      <Thead>
        <Tr>
          <Th>Tijd</Th>
          <Th>capaciteit</Th>
          <Th>link</Th>
          <Th>Verwijderen</Th>
        </Tr>
      </Thead>
      <Tbody>
        {timeSlots.data && (timeSlots.data).map((value: TimeSlot, index: number) => {
          return (
            <Tr key={index}>
              <Td>{new Date(value.time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</Td>
              <Td>
                <Editable id={value.id.toString()} onSubmit={(nextValue: string) => updateCapacity(nextValue, value.id)} defaultValue={value.capacity.toString()}>
                  <EditablePreview />
                  <EditableInput />
                </Editable>
              </Td>
              <Td>{value.link}</Td>
              <Td><DeleteIcon onClick={() => deleteTimeSlotMutation.mutate(value.id)} /></Td>
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  )
}