import { Box, Button, Flex, Text, Textarea } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";

export const FormMail: React.FunctionComponent<{ formId: number }> = (props) => {

  const [confirmationEmail, setConfirmationEmail] = useState('');
  const [reminderEmail, setReminderEmail] = useState('');

  const fetchMailTemplates = useCallback(() => {
    const apiUrl = `/api/forms/${props.formId}`;
    fetch(apiUrl, { credentials: 'include' })
      .then((response) => response.json())
      .then((data) => {
        setConfirmationEmail(data.confirmationEmail);
      });
  }, [props.formId]);

  useEffect(() => { fetchMailTemplates() }, [fetchMailTemplates])

  const submitTemplate = () => {
    const requestOptions = {
      method: 'PUT',
      credentials: 'include' as "include",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ confirmationEmail: confirmationEmail})
    };
    fetch(`/api/forms/${props.formId}`, requestOptions)
      .then(response => response.json());
  }

  const sendReminder = () => {
    const requestOptions = {
      method: 'POST',
      credentials: 'include' as "include",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ reminderEmail: reminderEmail})
    };
    fetch(`/api/forms/${props.formId}/reminder`, requestOptions)
      .then(response => response.json());
  }

  return (
    <Box>
      <Text fontSize={24} as="h2">Email template</Text>
      <Flex>
        <Box w="500px">
          <Textarea w="400px" h="400px" onChange={(e) => setConfirmationEmail(e.target.value)} value={confirmationEmail} placeholder="Email template" />
        </Box>
        <Button onClick={() => submitTemplate()}>Submit</Button>
      </Flex>
      <Text fontSize={24} as="h2">Stuur E-mail naar iedereen</Text>
      <Flex>
        <Box w="500px">
          <Textarea w="400px" h="400px" onChange={(e) => setReminderEmail(e.target.value)} value={reminderEmail} placeholder="Stuur email naar alle inschrijvingen" />
        </Box>
        <Button onClick={() => sendReminder()}>Stuur</Button>
      </Flex>
    </Box>
  )
}