export const EventsList = {
  parts: ["list", "listitem"],
  baseStyle: {
    list: {
      listStyleType: "none",
      marginLeft: 0,
      borderStyle: "solid",
      borderColor: "gray.300",
      borderWidth: "1px 0 1px 0",
    },
    listitem: {
      textAlign: "left",
      fontSize: 18,
      fontWeight: 500,
      borderStyle: "solid",
      borderColor: "gray.300",
      borderWidth: "0 0 1px 0",
      paddingLeft: "10px",
      paddingBottom: "5px",
      paddingTop: "5px",
      cursor: "pointer",
      '&:hover': {
        background: 'blue.50',
        color: 'button.700',
      },
      '&[aria-selected="true"]': {
        background: 'blue.100',
        color: 'button.700',
      }
    }
  },
  sizes: {},
  variants: {},
  defaultProps: {},
}