import { Box, Heading, Text } from "@chakra-ui/layout";
import { Button, Spinner, useClipboard } from "@chakra-ui/react";
import prettify from "html-prettify";
import React, { useMemo } from 'react';
import { useQuery } from "react-query";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { fetchFormFields } from "../../api/GenericFormsApi";
import { RegistrationField } from "../../models/RegistrationField";

function buildHtmlDocs(formId: number, fields: RegistrationField[]): string {
  var htmlDocBuilder = `<form action="${process.env.REACT_APP_BACKEND}/api/forms/${formId}/submissions" enctype="multipart/form-data" method="post">`;

  for (let field of fields) {
    htmlDocBuilder +=
      `
        <label>${field.label}</label>
        <input type="${field.fieldType}" name="${field.fieldName}" />
        <br>
        `
  }
  htmlDocBuilder += `
    <input type="submit" value="submit" />
    </form>`;

  return prettify(htmlDocBuilder);
}

export const FormUsage: React.FunctionComponent<{ formId: number }> = ({ formId }) => {


  const { status, data: fields } = useQuery<RegistrationField[], Error>(["formfields", formId], () => fetchFormFields(formId), {
    staleTime: 60 * 1000,
  });

  const htmlDocs = useMemo(() => {

    if (fields) {
      return buildHtmlDocs(formId, fields)
    } else {
      return ""
    }

  }, [formId, fields]);


  const { hasCopied, onCopy } = useClipboard(htmlDocs)


  const renderHtmlDocs = () => {

    if (status === "loading") {
      return (
        <Spinner />
      )
    } else if (status === "error") {
      return (
        <Text>Could not load fields</Text>
      )
    } else {
      return (
        <Box pos="relative">
          <SyntaxHighlighter language="html" showLineNumbers={true} wrapLines={true} style={a11yDark}>
            {htmlDocs}
          </SyntaxHighlighter>
          <Button onClick={onCopy} colorScheme="button" position="absolute" bottom="5px" right="5px">
            {hasCopied ? "Copied" : "Copy"}
          </Button>
        </Box>


      )
    }
  }

  return (
    <Box>
      <Heading as="h2">HTML</Heading>
      {renderHtmlDocs()}
    </Box>
  )
}