import { Registration } from "../models/Registration";

export async function fetchRegistrations(eventId: number, daySlotId: number | null): Promise<Registration[]> {
  var apiUrl = `/api/registrations/${eventId}`;
  if (daySlotId !== null) {
    apiUrl += '/dayslots/' + daySlotId
  }
  const response = await fetch(apiUrl, { credentials: 'include' });
  if (!response.ok) {
    throw new Error("fetchRegistrations was not ok");
  }
  return response.json();
}

export async function deleteRegistration(eventId: number, registrationId: number): Promise<Response> {
  const requestOptions = {
    method: 'DELETE',
    credentials: 'include' as "include",
  };
  const response = await fetch(`/api/registrations/${eventId}/${registrationId}`, requestOptions);
  if (!response.ok) {
    throw new Error("deleteRegistration was not ok")
  }
  return response
}

export async function sendRegistrationReminder(registrationId: number): Promise<Response> {
  var apiUrl = `/api/registrations/${registrationId}/sendreminder`;
  const response = await fetch(apiUrl, { credentials: 'include' });
  if (!response.ok) {
    throw new Error("sendRegistrationsReminder was not ok");
  }
  return response
}
