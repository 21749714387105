import { ArrowUpDownIcon, DeleteIcon } from "@chakra-ui/icons";
import { Box, Button, Checkbox, FormControl, FormHelperText, FormLabel, Input, Select, Table, Tbody, Td, Text, Th, Thead, Tr, useMultiStyleConfig } from "@chakra-ui/react";
import React, { useState } from "react";
import { DragDropContext, Draggable, DraggableProvided, DraggableStateSnapshot, Droppable, DroppableProvided, DroppableStateSnapshot, DropResult } from "react-beautiful-dnd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { addFormField, deleteFormField, fetchFormFields, updateFields } from "../../api/GenericFormsApi";
import { GenericForm } from "../../models/GenericForm";
import { RegistrationField } from "../../models/RegistrationField";


const reorder = (list: RegistrationField[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  console.log("Debugje")
  console.log(result)
  return result;
};

export const FormFields: React.FunctionComponent<{ form: GenericForm }> = ({ form }) => {
  const [newFieldLabel, setNewFieldLabel] = useState('');
  const [newFieldName, setNewFieldName] = useState('');
  const [newFieldType, setNewFieldType] = useState('');
  const [newFieldRequired, setNewFieldRequired] = useState(true);
  const styles = useMultiStyleConfig("EventFields", {});

  const queryClient = useQueryClient();
  const fields = useQuery<RegistrationField[], Error>(["formfields", form.id], () => fetchFormFields(form.id), {
    staleTime: 60 * 1000,
  });
  const addField = useMutation(() => addFormField(form.id,
    { label: newFieldLabel, fieldName: newFieldName, fieldType: newFieldType, required: newFieldRequired }
  ), {
    onSuccess: () => {
      queryClient.invalidateQueries(["formfields", form.id]);
      clearFields();
    }
  });

  const deleteField = useMutation((fieldId: number) => deleteFormField(form.id, fieldId), {
    onSuccess: () => {
      queryClient.invalidateQueries(["formfields", form.id]);
    }
  })

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(
      (fields.data as RegistrationField[]),
      result.source.index,
      result.destination.index
    );
    updateFields(form.id, items)
    queryClient.setQueryData(["formfields", form.id], items);
  }

  const clearFields = () => {
    setNewFieldLabel('');
    setNewFieldName('');
    setNewFieldType('');
    setNewFieldRequired(true);
  }

  return (
    <Box>
      <Text as="h2">Velden</Text>
      <Table sx={styles.table} variant="simple">
        <Thead>
          <Tr>
            <Th w="20%">Label</Th>
            <Th w="20%">Naam</Th>
            <Th w="15%">Type</Th>
            <Th w="15%">Verplicht</Th>
            <Th w="15%">Verwijder</Th>
            <Th w="15%">Volgorde</Th>
          </Tr>
        </Thead>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="fieldsDroppable">
            {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
              <Tbody ref={provided.innerRef}
                {...provided.droppableProps}>
                {fields.data && fields.data.map((value: RegistrationField, index: number) =>
                  <Draggable key={value.id} draggableId={value.id!.toString()} index={index}>
                    {(providedDraggable: DraggableProvided, snapshotDraggable: DraggableStateSnapshot) => (
                      <Tr key={value.id} ref={providedDraggable.innerRef} {...providedDraggable.draggableProps} style={{
                        ...providedDraggable.draggableProps.style
                      }}
                      >
                        <Td w="20%">{value.label}</Td>
                        <Td w="20%">{value.fieldName}</Td>
                        <Td w="15%">{value.fieldType}</Td>
                        <Td w="15%">{value.required ? "ja" : "nee"}</Td>
                        <Td w="15%"><DeleteIcon cursor="pointer" onClick={() => deleteField.mutate(value.id!)}></DeleteIcon></Td>
                        <Td w="15%" {...providedDraggable.dragHandleProps} ><ArrowUpDownIcon  ></ArrowUpDownIcon></Td>
                      </Tr>
                    )}
                  </Draggable>
                )}
                {provided.placeholder}
              </Tbody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
      <Text as="h2">Nieuw veld</Text>
      <Box display={{ md: "flex" }}>
        <FormControl>
          <FormLabel>Label</FormLabel>
          <Input w="200px" type="text" name="newFieldLabel" onChange={(e) => setNewFieldLabel(e.target.value)} value={newFieldLabel} placeholder="label"></Input>
          <FormHelperText>Label dat op de website komt</FormHelperText>
        </FormControl>
        <FormControl>
          <FormLabel>Naam</FormLabel>
          <Input w="200px" type="text" name="newFieldName" onChange={(e) => setNewFieldName(e.target.value)} value={newFieldName} placeholder="naam"></Input>
          <FormHelperText>Interne naam</FormHelperText>
        </FormControl>
      </Box>
      <Box pt="20px" display={{ md: "flex" }}>
        <FormControl>
          <FormLabel>Type</FormLabel>
          <Select w="200px" placeholder="Kies type" onChange={(e) => setNewFieldType(e.target.value)} value={newFieldType}>
            <option value="text">tekst</option>
            <option value="email">email</option>
            <option value="options">opties</option>
            <option value="file">bestand</option>
          </Select>
          <FormHelperText>Type veld</FormHelperText>
        </FormControl>
        <FormControl>
          <FormLabel>Verplicht</FormLabel>
          <Checkbox name="newFieldRequired" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewFieldRequired(e.target.checked)} defaultChecked>Verplicht</Checkbox>
          <FormHelperText>Geeft aan of dit veld verplicht ingevuld moet worden</FormHelperText>
        </FormControl>
      </Box>
      <Box pt="20px">
        <Button colorScheme="button" onClick={() => addField.mutate()}>Toevoegen</Button>
      </Box>
    </Box>
  )

}
