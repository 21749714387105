import { Button, Center, Icon, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, VStack } from "@chakra-ui/react";
import React from "react";
import { FaFacebook, FaGithub, FaGitlab } from 'react-icons/fa';

export const LoginModal: React.FunctionComponent<{}> = (props) => {

  const loginGitlab = () => {
    window.location.href = "/auth/gitlab"
  }

  return (
    <Modal isCentered={true} size="xl" isOpen={true} onClose={() => { }}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader><Center>Welkom!</Center></ModalHeader>
        <ModalBody>
          <VStack color="white" >
            <Button w="300px" bg="#f46b25" onClick={loginGitlab}>
              <Icon mr="10px" as={FaGitlab}></Icon>
               Login with GitLab
            </Button>
            <Button isDisabled={true} w="300px" bg="#58a6ff">
              <Icon mr="10px" as={FaGithub}></Icon>
               Login with GitHub
            </Button>
            <Button isDisabled={true} w="300px" bg="#097eeb">
              <Icon mr="10px" as={FaFacebook}></Icon>
               Login with Facebook
            </Button>
          </VStack>
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
