import { Box, Button, Text } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const FormSettings: React.FunctionComponent<{ formId: number }> = (props) => {

  const [showDate, setShowDate] = useState<Date | null>(null);
  const [hideDate, setHideDate] = useState<Date | null>(null);

  const fetchFormSettings = useCallback(() => {
    const apiUrl = `/api/forms/${props.formId}/settings`;
    fetch(apiUrl, { credentials: 'include' })
      .then((response) => response.json())
      .then((data) => {
        if (data.showDate) { setShowDate(new Date(data.showDate)) }
        else { setShowDate(null) }
        if (data.hideDate) { setHideDate(new Date(data.hideDate)) }
        else { setHideDate(null) }
      });
  }, [props.formId]);

  useEffect(() => { fetchFormSettings() }, [fetchFormSettings])

  const submitSettings = () => {
    const requestOptions = {
      method: 'PUT',
      credentials: 'include' as "include",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ showDate: showDate, hideDate: hideDate })
    };
    fetch(`/api/forms/${props.formId}/settings`, requestOptions)
      .then(response => response.json());
  }

  return (
    <Box>
      <Text fontSize={24} as="h2">Settings</Text>
      <Box>
        <Text>Toon vanaf: </Text>
        <DatePicker selected={showDate} onChange={date => setShowDate(date as Date)} showTimeSelect dateFormat="dd/MM/yyyy HH:mm" />
      </Box>
      <Box>
        <Text>Verberg vanaf: </Text>
        <DatePicker selected={hideDate} onChange={date => setHideDate(date as Date)} showTimeSelect dateFormat="dd/MM/yyyy HH:mm" />
      </Box>
      <Box>
        <Button onClick={() => submitSettings()}>Opslaan</Button>
      </Box>
    </Box>
  )
}